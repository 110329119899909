@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600&display=swap");

$font-family: "Montserrat", sans-serif;

$black: black;
$gray: #181d2a;
$blue: #339bc0; //#267491;
$dark-blue: #082d3a;
$light-blue: #2e74b7;
$light-bluewhite: #3cb2dc;
$light-gray: #a9b7cb;

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $blue;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $gray;
}

.carousel .thumbs-wrapper {
  margin: 20px 0 !important;
}
.carousel-style {
  padding-bottom: 10vw;
}
.carousel .carousel-status {
  font-family: $font-family;
  font-size: 20px !important;
}

body {
  font-size: 16px;
  background: $black;
  font-family: $font-family;
  overflow-x: hidden;
  z-index: 1;
}

b {
  font-weight: 600;
  color: $light-bluewhite;
}

.menu-opened {
  overflow: hidden;
}

.lang-wrapper {
  width: 100%;
  text-align: right;
}
.langIcon {
  width: 35px;
  height: auto;
  padding: 10px;
  cursor: pointer;
}

.langIcon:active {
  animation: shake 0.5s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
}

.App {
  width: 100%;
  position: relative;
  overflow: hidden;
}

.error-page-container {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 10vw;
  width: 80vw;
  margin: 30vh 10vw 10vh 10vw;
}

.error-page-header {
  align-self: center;
  font-size: 3vw;
  line-height: 150%;
  color: white;
  text-align: left;
}

@media only screen and (max-device-width: 480px) {
  .error-page-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10vw;
    width: 80vw;
    margin: 20vh 10vw 10vh 10vw;
  }
  .error-page {
    width: 50vw;
    align-self: center;
    margin: auto;
  }
  .error-page-header {
    font-size: 6vw;
    line-height: 150%;
    text-align: center;
  }
}

.App-header {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  color: white;
  padding-bottom: 100px;
  min-height: 100vh;
}

.about-image {
  height: auto;
  width: 100%;
  object-fit: contain;
  max-height: 350px;
}

.section {
  margin-left: 10vw;
  width: 80vw;
  padding-bottom: 5vh;
  font-weight: 600;
  padding-top: 30px;
}

.navbar {
  -webkit-backdrop-filter: blur(40px);
  backdrop-filter: blur(40px);
  opacity: 0.9;
  background-color: black;
  position: fixed;
  top: 0;
  text-align: center;
  z-index: 100;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr auto auto;
  grid-template-columns: 1fr auto auto;

  width: 80vw;

  height: 100px;

  padding-left: 10vw;
  padding-right: 10vw;

  gap: 10px;
}

.mva {
  margin-top: auto;
  margin-bottom: auto;
}

.z-20 {
  z-index: 20;
}

.z-10 {
  z-index: 10;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-3px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(3px, 0, 0);
  }
}

.back-icon {
  fill: white;
  cursor: pointer;

  // padding-left: 5px;
  // padding-right: 0;
  -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.back-icon:hover {
  transform: translate3d(-5px, 0, 0);
}

.main-logo {
  // margin: 1vw;
  // margin-left: 7.2vw;
  height: 80px;
  width: auto;
  display: block;
  text-align: left;
}

.main-menu {
  display: block;
  text-align: right;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  padding: 1vw 7.5vw 1vw 1vw;
  font-size: 0.8em;
}

.heading-background {
  position: fixed;
  opacity: 0.1;
  font-size: 15em;
  margin: auto;
  line-height: 72%;
  top: 15vh;
  z-index: -100;
  color: $blue;
}

header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.Contact-content {
  padding-bottom: 20vh;
}

nav li {
  display: inline-block;
  cursor: pointer;
  font-size: 1.5em;
  .link {
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
  }
}

li .link:hover {
  opacity: 1;
}

.back-icon {
  height: 40px;
}

.active {
  color: $blue !important;
  opacity: 1;
}

a {
  // text-transform: uppercase;
  font-weight: 600;
}

.section-header {
  padding-bottom: 5vh;
}

.header-title {
  .header-line {
    padding-bottom: 5vh;
    font-weight: 500;
  }

  button {
    background: white;
    border: 0px;
    padding: 0.5em 1em;
    font-size: 25px;
    -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);

    span {
      cursor: pointer;
      display: inline-block;
      position: relative;
      transition: 0.5s;
      &:after {
        content: "\00bb";
        color: $blue;
        position: absolute;
        opacity: 0;
        top: 0;
        right: -5px;
        transition: 0.5s;
      }
    }

    &:hover {
      // padding: 0.7em 1.2em;
      span {
        padding-right: 25px;
        &:after {
          opacity: 1;
          right: -15px;
        }
      }
    }

    a {
      color: $blue;
      text-decoration: none;
    }
  }

  position: relative;
  font-size: 5vw;
  text-align: left;
  margin: auto 0;
}

.About {
  color: white;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 80vw;
}

.about-content {
  color: white;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10px 1fr;
  grid-template-columns: 1fr 1fr;
  // grid-template-rows: 1fr auto;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;

  p {
    font-size: 1.3em;
    line-height: 160%;
    font-weight: 400;
    // text-align: justify;
  }
}

.Work {
  color: white;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.Skills {
  color: white;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.skills-graphs {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 3vw 1fr 3vw 1fr 3vw 1fr 3vw 1fr;
  grid-template-columns: repeat(5, 1fr);
  -webkit-gap: 3vw;
  -moz-gap: 3vw;
  gap: 3vw;
  width: 100%;
}

.skill-icon {
  height: 5vw;
}

.social-icon {
  height: 60px;
  width: auto;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  &:hover {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.project {
  position: relative;
}

.project-title {
  padding: 5px 0;
  text-transform: uppercase;
  pointer-events: none;
  color: $blue;
  font-size: 3.5vw;
  position: absolute;
  top: 35%;
  width: 100%;
  text-align: center;
  background: $dark-blue;
}

.project-desc {
  font-weight: 400;
  padding: 5px 0;
  color: white;
  pointer-events: none;
  font-size: 3vw;
  padding-bottom: 1vh;
  position: absolute;
  bottom: 35%;
  width: 100%;
  text-align: center;
  background: $dark-blue;
}

.heading {
  font-size: 4em;
  text-align: left;
}

.big-text {
  font-size: 4em;
  font-weight: 400;
  text-align: left;
  padding-bottom: 2vh;
}

.Work .work-content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 10px 1fr;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 26px;
  width: 80vw;
}

.img-container {
  width: calc((80vw - 26px) / 2);
  height: calc((80vw - 26px) / 2);
  overflow: hidden;
}

.My-Project {
  width: 80vw;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: auto;
  padding-top: 110px;
  color: white;
}

.project-img {
  -webkit-filter: grayscale(100%) blur(0px); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%) blur(0px);
  -webkit-transition: -webkit-filter 0.2s;
  transition: -webkit-filter 0.2s;
  -o-transition: filter 0.2s;
  transition: filter 0.2s;
  transition: filter 0.2s, -webkit-filter 0.2s;

  &:hover {
    -webkit-filter: grayscale(0%) blur(0px); /* Safari 6.0 - 9.0 */
    filter: grayscale(0%) blur(0px);
  }
}

.project-header {
  color: $blue;
  padding-bottom: 1vh;
}

.project-header > span {
  display: inline-block;
}
.project-header > .year {
  color: $dark-blue;
  font-size: 0.6em;
  display: block;
  float: right;
}

.My-Project > .main-points {
  font-size: 1.6em;
  text-align: left;
  width: 100%;
  padding-bottom: 2vh;
  font-style: italic;
}

.My-Project > .link {
  color: #339bc0 !important;
  padding-bottom: 0 !important;
  line-height: 200% !important;
}
.My-Project > .description {
  font-size: 1.3em;
  line-height: 150%;
  text-align: justify;
  text-justify: inter-character;
  width: 100%;
  padding-bottom: 5vh;
  color: white;
}
p.legend {
  font-size: 16px !important;
  background: rgba(8, 45, 58, 0.9) !important;
  color: white !important;
  border-radius: 0 !important;
}
.project img {
  height: 100%;
  -webkit-transition: -webkit-transform 0.2s ease-out;
  transition: -webkit-transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out;
  transition: transform 0.2s ease-out, -webkit-transform 0.2s ease-out;
  -o-object-fit: cover;
  object-fit: cover;
  -webkit-transform: translateX(-25%);
  -ms-transform: translateX(-25%);
  transform: translateX(-25%);
  &:hover {
    -webkit-transform: translateX(0%);
    -ms-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
  }
}

.Contact {
  color: white;
  position: relative;
  text-align: center;

  // padding-bottom: 20vh !important;

  .email {
    color: white;
    font-size: 2em;
  }
  p {
    font-size: 25px;
    padding-top: 15px;
    opacity: 0.6;
  }

  li {
    display: inline-block;
    margin: auto;

    a {
      text-decoration: none;
      color: white;
    }
  }
}

.ContactForm {
  color: white;
  position: relative;
  text-align: center;
  &_form {
    width: 100%;
    max-width: 800px;
    margin: auto;

    input[type="text"],
    textarea {
      font-family: $font-family;
      font-size: 22px;
      font-weight: 500;
      color: $dark-blue;

      width: 100%;
      padding: 12px 20px;
      margin: 8px 0;
      display: inline-block;
      border: 3px solid $dark-blue;
      // border-radius: 4px;
      box-sizing: border-box;
    }
    input[type="text"]:focus,
    textarea:focus {
      border: 3px solid $blue;
    }
    input[type="submit"] {
      width: 100%;
      background-color: white;
      color: $blue;
      // padding: 14px 20px;
      margin: 8px 0;
      border: none;
      // border-radius: 4px;
      cursor: pointer;

      border: 0px;
      padding: 0.5em 1em;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 600;
      -webkit-transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    input[type="submit"]:hover {
      background-color: $dark-blue;
    }
  }
}

.social-contacts {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 2vw 1fr 2vw 1fr;
  grid-template-columns: repeat(3, 1fr);
  -webkit-column-gap: 2vw;
  -moz-column-gap: 2vw;
  column-gap: 2vw;
}

.social-contacts-1 {
  -ms-grid-columns: 1fr;
  grid-template-columns: repeat(1, 1fr);
}

.social-contacts-2 {
  -ms-grid-columns: 1fr 2vw 1fr;
  grid-template-columns: repeat(2, 1fr);
}

.social-contacts-3 {
  -ms-grid-columns: 1fr 2vw 1fr 2vw 1fr;
  grid-template-columns: repeat(3, 1fr);
}

.social-contacts-4 {
  -ms-grid-columns: 1fr 2vw 1fr 2vw 1fr 2vw 1fr;
  grid-template-columns: repeat(4, 1fr);
}

@media only screen and (max-device-width: 370px) {
  .main-logo {
    height: 12vw !important;
    min-height: 30px !important;
  }
}
/* SMARTPHONES PORTRAIT */

@media only screen and (max-device-width: 480px) {
  .navbar {
    width: 90vw;
    height: 80px;
    padding-left: 5vw;
    padding-right: 5vw;
    grid-gap: 0px;
    gap: 0px;
  }

  .section {
    margin-left: 5vw;
    width: 90vw;
    padding-bottom: 3vh;
  }

  .heading-background {
    font-size: 7em;
  }
  .main-logo {
    height: 12vw;
    min-height: 55px;
    // margin: 3vw 0 3vw 3vw;
  }
  nav li .link {
    padding: 0;
  }
  .main-menu {
    margin-top: 3vw;
    margin-bottom: 3vw;
    padding: 1vw 5vw 1vw 1vw;
  }
  .App-header {
    min-height: auto;
    margin-top: 100px;
    padding-bottom: 10vw;
  }
  .header-title {
    font-size: 8vw;
  }

  .header-title .header-line {
    padding-bottom: 2vh;
  }
  .header-title button {
    margin-top: 10px;
    font-size: 20px;
  }

  .about-content {
    :nth-child(1) {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }
    :nth-child(2) {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }

    -ms-grid-columns: 1fr;

    grid-template-columns: 1fr;
    p {
      font-size: 1em;
      line-height: 130%;
      // text-align: justify;
    }
  }

  .about-image {
    padding-bottom: 5vw;
  }
  .heading {
    font-size: 2em;
    padding-bottom: 2vh;
  }
  .My-Project {
    width: 90vw;
    padding-top: 90px;
    .main-points {
      font-size: 1.2em;
    }
    .description {
      font-size: 1em;
      line-height: 130%;
    }
  }
  p.legend {
    font-size: 10px !important;
    bottom: 20px !important;
    opacity: 0.6 !important;
    padding: 5px !important;
  }

  .carousel .carousel-status {
    font-size: 12px !important;
  }

  .carousel .control-dots .dot {
    width: 5px !important;
    height: 5px !important;
  }
  .carousel .control-dots {
    margin: 7px 0 !important;
  }

  .project-title {
    font-size: 10vw;
  }

  .project-desc {
    font-size: 8vw;
  }
  .img-container {
    width: 90vw;
    height: 90vw;
  }
  .Work .work-content {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    row-gap: 5vw;
  }
  .Contact {
    text-align: center;
    // padding-bottom: 10vh;
    p {
      font-size: 5vw;
    }
  }
  .ContactForm {
    padding-bottom: 20vh;
    &_form {
      input[type="text"],
      textarea {
        font-size: 18px;
      }
      input[type="submit"] {
        font-size: 20px;
      }
    }
  }
  .big-text {
    text-align: center;
    font-size: 2em;
    padding-bottom: 4vw;
  }

  .skills-graphs {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3vw;
    gap: 3vw;
    width: 100%;
  }
  .skill-icon {
    height: 20vw;
  }

  .dropdown-content p {
    font-size: 20px;
    padding: 10px 10px;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 768px) {
  .navbar {
    width: 80vw;
    height: 100px;
    padding-left: 10vw;
    padding-right: 10vw;
    grid-gap: 5px;
    gap: 5px;
  }

  .main-menu {
    padding: 1vw 5vw 1vw 1vw;
  }
  .header-title {
    font-size: 8vw;
  }
  .section {
    padding-bottom: 5vw;
  }
  .About {
    width: 80vw;
  }

  .ContactForm {
    padding-bottom: 15vh;
  }

  .about-image {
    padding-bottom: 5vw;
  }
  .heading {
    font-size: 4em;
    padding-bottom: 3vh;
  }
  .big-text {
    font-size: 3em;
    font-weight: 400;
    text-align: left;
    padding-bottom: 4vh;
  }
  .main-logo {
    height: 10vw;
    max-height: 60px;
    // margin: 3vw 0 0 4vw;
  }

  .about-content {
    :nth-child(1) {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }
    :nth-child(2) {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }

    -ms-grid-columns: 1fr;

    grid-template-columns: 1fr;
    p {
      font-size: 1.3em;
      line-height: 120%;
      // text-align: justify;
    }
  }

  .My-Project {
    width: 80vw;
    padding-top: 100px;
    .main-points {
      font-size: 1.2em;
    }
    .description {
      font-size: 1em;
      line-height: 130%;
    }
  }

  .project-title {
    font-size: 8vw;
    top: 37%;
  }
  .project-desc {
    font-size: 7vw;
    bottom: 37%;
  }
  .img-container {
    width: 80vw;
    height: 80vw;
  }
  .Work .work-content {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    row-gap: 5vw;
  }

  .skills-graphs {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3vw;
    gap: 3vw;
    width: 100%;
  }
  .skill-icon {
    height: 10vw;
  }

  .dropdown-content p {
    font-size: 2.3vw;
    padding: 10px 10px;
  }
}
/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width: 769px) and (max-device-width: 1024px) {
  .main-menu {
    padding: 1vw 5vw 1vw 1vw;
  }
  .Work .work-content {
    grid-gap: 20px;
  }

  .img-container {
    width: calc((80vw - 20px) / 2);
    height: calc((80vw - 20px) / 2);
    overflow: hidden;
  }

  .about-content {
    grid-gap: 30px;
    :nth-child(1) {
      -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
      order: 2;
    }
    :nth-child(2) {
      -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
      order: 1;
    }

    -ms-grid-columns: 1fr;

    grid-template-columns: 1fr;
    p {
      font-size: 1em;
      line-height: 130%;
      // text-align: justify;
    }
  }
  .main-logo {
    height: 10vw;
    max-height: 60px;
    // margin: 2vw 0 0 4vw;
  }
  .My-Project {
    width: 80vw;
    padding-top: 12vw;
  }

  body {
    font-size: 10px;
  }

  header h1 {
    font-size: 5em;
    padding: 1em;
    margin-right: 0em;
  }

  .header-title {
    font-size: 4.5em;
    text-align: left;
    top: 1em;
  }

  .about {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    top: -10em;

    img {
      display: none;
    }
  }

  .Work {
    // top: 4em;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;

    .Work-content {
      display: -ms-grid;
      display: grid;
      -ms-grid-columns: 1fr 5em 1fr;
      grid-template-columns: 1fr 1fr;
      grid-gap: 5em;
    }
  }

  .About {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;

    .about-content {
      p {
        font-size: 2.5vw;
        line-height: 130%;
        // text-align: justify;
        width: 100%;
      }
    }
  }

  .Skills {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .skills-graphs {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: 1fr 1fr 1fr 1fr;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 3vw;
    gap: 3vw;
    width: 100%;
  }
  .skill-icon {
    height: 10vw;
  }

  .dropdown-content p {
    font-size: 15px;
    padding: 6px 6px;
  }

  .section-header {
    padding-bottom: 2vh;
  }

  .Contact {
    // padding-bottom: 10vh;
    h1 {
      font-size: 4em;
    }

    .email {
      font-size: 3.5em;
    }

    li {
      display: block;
      padding: 2em;
      text-align: center;

      a {
        font-size: 2.5em;
      }
    }
  }

  .ContactForm {
    padding-bottom: 10vh;
  }
  // .footer {
  //   top: 4em;
  //   padding: 1em;
  //   font-size: 2em;
  // }
}

/* Large screens ----------- */
@media only screen and (min-width: 1440px) {
  // .navbar {
  //   height: 120px;
  // }
  .My-Project {
    padding-top: 120px;
  }

  .main-logo {
    height: 4vw;
  }
  .img-container {
    width: calc((80vw - 30px) / 3);
    height: calc((80vw - 30px) / 3);
  }
  .Work .work-content {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
  }
  .main-menu {
    font-size: 1vw;
  }
  .heading-background {
    font-size: 20vw;
  }
  .heading {
    font-size: 5vw;
  }
  .header-title {
    font-size: 5vw;
  }

  // .langIcon {
  //   width: 50px;
  // }
  .about-content p {
    font-size: 1.2vw;
    line-height: 175%;
    // text-align: left;
  }
  .header-title button {
    font-size: 2.5vw;
  }
  .project-title {
    font-size: 3vw;
    top: 34%;
  }
  .project-desc {
    font-size: 2.5vw;
    bottom: 34%;
  }
}
