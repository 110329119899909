.CircularProgressbar-path {
  stroke: #339bc0 !important;//#267491 !important;
}
.CircularProgressbar-trail {
  stroke: black !important;
}
.CircularProgressbar-text {
  fill: white !important;
}
.CircularProgressbar-background {
  fill: green !important;
}

// .circular-chart {
  // width: 10vw !important;
// }

// [data-test-id="CircularProgressbarWithChildren"] {
  // display: inline-block;
  // padding-right: 3.5vw;
// }

[data-test-id="CircularProgressbarWithChildren"]:hover .dropdown-content {
  height: 100%;
  width: 100%;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  position: absolute;
  background-color: rgba(255,255,255,0.9);
  //   min-width: 160px;
  //   box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in;
  overflow: hidden;
  height: 0;
  width: 0;
}
/* Links inside the dropdown */
.dropdown-content p {
  // font-family: "Montserrat", sans-serif;
  text-decoration: none;
  font-size: 20px;
  color: #267491;
//   text-transform: uppercase;
  padding: 5%;
  display: block;
  font-weight: 500;
}
