.side__menu {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 10;
    height: calc(100vh - 100px);
    display: block;
    width: 450px;
    max-width: 100%;
    margin-top: 0px;
    padding-top: 100px;
    padding-right: 0px;
    align-items: stretch;
    backdrop-filter: blur(40px);
    opacity: 1;
    background-color: black;
    transform: translateX(100%);
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

@media only screen and (max-device-width: 425px) {
    nav {
        width: 100vw;
        height: calc(100vh - 80px);
    }
}

.side__menu.opened {
    transform: translateX(0);
}

.nav__link {
    position: relative;
    display: block;
    text-align: left;
    max-width: 100%;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 10%;
    padding-right: 10%;
    background-position: 88% 50%;
    background-size: 36px;
    background-repeat: no-repeat;
    transition: background-position 300ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
    text-decoration: none;
    color: #fff;
    font-size: 32px;
    line-height: 120%;
    font-weight: 500;
    cursor: pointer;
}

.nav__link:hover {
    background-position: 90% 50%;
}

.navbar__menu {
    background-color: transparent;
    display: block;
    transform-origin: 16px 11px;
    float: left;
    // margin-right: 3vw;
    outline: 0;
    border: 0;
    // padding: 12px;
    background: none;

    span {
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }

    :focus {
        outline: 0;
    }

    :hover {
        span:nth-of-type(1) {
            width: 33px;
        }

        span:nth-of-type(2) {
            width: 40px;
        }

        span:nth-of-type(3) {
            width: 30px;
        }
    }

    &.active {
        span:nth-of-type(1) {
            transform: rotate(45deg) translate(10px, 10px);
            width: 40px;
        }

        span:nth-of-type(2) {
            opacity: 0;
            pointer-events: none;
        }

        span:nth-of-type(3) {
            transform: rotate(-45deg) translate(7px, -7px);
            width: 40px;
        }
    }
}

.navbar__burger {
    display: block;
    width: 40px;
    height: 5px;
    margin-bottom: 7px;
    background-color: #fff;
}

.navbar__burger_last {
    display: block;
    width: 40px;
    height: 5px;
    background-color: #fff;
}
